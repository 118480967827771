import React, { useEffect, useState } from 'react';
import { Layouts } from '../components/components';
import style from './videos_template.module.scss';
import { getVideoDataById } from '../api/strapi';
import { StrapiVideo } from '../graphql-types';

interface PageContext {
  title: string;
  script: string;
}

interface PageTemplateProps {
  pageContext: PageContext;
}

const VideosTemplate: React.FC<PageTemplateProps> = ({ pageContext }) => {
  const [data, setData] = useState<StrapiVideo | null>(null);

  const { id, script, title } = pageContext;
  const { IndexLayout, AdaptiveLayout } = Layouts;

  useEffect(() => {
    getVideoDataById(id).then(res => {
      setData(res);
    });
  }, []);

  return (
    <IndexLayout>
      <AdaptiveLayout onDesktop onTablet>
        <div className={style.root}>
          <h3 className={style.title}>{data?.Title ? data.Title : title}</h3>
          <div className={style.videoWrapper}>
            <div className={style.video} dangerouslySetInnerHTML={{ __html: data?.HTML ? data.HTML : script }} />
          </div>
        </div>
      </AdaptiveLayout>
      <AdaptiveLayout onMobile>
        <div className={style.root}>
          <h3 className={style.title}>{data?.Title ? data.Title : title}</h3>
          <div className={style.videoWrapper}>
            <div className={style.video} dangerouslySetInnerHTML={{ __html: data?.HTML ? data.HTML : script }} />
          </div>
        </div>
      </AdaptiveLayout>
    </IndexLayout>
  );
};

export default VideosTemplate;
